import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useRegisterMutation } from '../../redux/api/userAPI';

const Signup = ({name,gender,dob,phone}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const [register] = useRegisterMutation();
  const navigate =useNavigate();
  
  // Handle Signup with email and password
  const handleSignup = async () => {
    if (password === confirmPassword) {
      try {
        const userData = {
          first_name:name.split(' ')[0],
          last_name:'',
          email,
          phone,
          password,
          gender,
          date_of_birth:dob
        };
  
        const res = await register(userData);
  
        if (res.data) {
          toast.success(res.data.message);
          console.log('User registered:');
          navigate("/login");
        } else {
          const error = res.error;
          const message = error?.data?.message || "An error occurred"; // Fallback for missing message
          toast.error(message);
        }
      } catch (error) {
        
      }
    } else {
      toast.error('Passwords do not match');
    }
  }; 

  return (
    <Grid container spacing={2} alignItems="center"  justifyContent="center" style={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Box p={4} boxShadow={3} borderRadius={2}>
          <Typography variant="h4" gutterBottom>Sign Up</Typography>

          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            size="small"
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            size="small"
          />
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
            size="small"
          />

          <Button variant="contained" color="primary" fullWidth onClick={handleSignup}>
            Sign Up with Email
          </Button>
            {/* <Typography align="center"  marginY={2}><Divider>OR</Divider></Typography>
          <Button variant="contained" color="secondary" fullWidth onClick={handleGoogleSignIn}>
            Sign In with Google
          </Button> */}

          
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signup;
