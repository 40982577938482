import React,{ useState, Suspense} from 'react';
import { Grid, useMediaQuery,Drawer,CssBaseline  } from '@mui/material';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import { Toaster } from "react-hot-toast";
import { useSelector } from 'react-redux';

import '../src/components/SideNav.css'
import Divider from '@mui/material/Divider';
import Loader from './components/Loader/Loader.jsx';

import LoginPage from'./components/Auth/LoginPage.jsx';
import ProtectedRoute from'./components/Admin/ProtectedRoute.js';
import LandingPage from'./components/Home/LandingPage.jsx';
import TermsAndConditions from'./components/Home/TermsAndConditions.jsx';
import PrivacyPolicy from'./components/Home/PrivacyPolicy.jsx';
import AboutUs from'./components/Home/AboutUs.jsx';
import BookAppointmentOutside from'./components/Appointment/BookAppointmentOutside.jsx';
import PersonalInfo from'./components/Auth/PersonalInfo.jsx';
import ForgotPasswordPage from './components/Auth/ForgotPassword.jsx';
import ResetPassword from './components/Auth/ResetPassword.jsx';

const SideNav = React.lazy(() => import('./components/SideNav'));
const Header = React.lazy(() => import('./components/Header'));
const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const Policy = React.lazy(() => import('./components/Policy/Policy'));
const PolicyDetail = React.lazy(() => import('./components/Policy/PolicyDetail'));
const UpdateDetails = React.lazy(() => import('./components/Policy/UpdateDetails'));
const FamilySpace = React.lazy(() => import('./components/FamilySpace/FamilySpace'));
const MemberPolicy = React.lazy(() => import('./components/FamilySpace/MemberPolicy'));
const Agents = React.lazy(() => import('./components/Agents/Agents'));
const AgentPolicy = React.lazy(() => import('./components/Agents/AgentPolicy'));
const MyProfile = React.lazy(() => import('./components/MyProfile/MyProfile.jsx'));
const Appointment = React.lazy(() => import('./components/Appointment/Appointment.jsx'));
const BookAppointment = React.lazy(() => import('./components/Appointment/BookAppointmentMobile.jsx'));





// import PolicyCalendar from './components/PolicyCalendar/PolicyCalendar';
// import ClaimAssistance from './components/ClaimAssistance/ClaimAssistance';
// import SOPpage from './components/DownloadSOP/SOPpage';
// import DownloadSOP from './components/DownloadSOP/DownloadSOP.jsx';
// import Blog from './components/Blog/Blog.jsx';
// import BlogDetail from './components/Blog/BlogDetail.jsx';
// import ClaimDetail from './components/ClaimAssistance/ClaimDetail.jsx';

function App() {
  const isMobile = useMediaQuery('(max-width:1200px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [token ,settoken] = useState(null);
  const {user} = useSelector((state) => state.userReducer );
   
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // useEffect(() => {
  //   settoken(localStorage.getItem("token"));
  //   const fetchUserr = async () => {
  //     console.log(token);
      
  //   };

  //   fetchUserr();
  // }, [token]);
  return (
    <Router>
      <CssBaseline />
        <Routes>
            <Route path="/" element={<LandingPage />} /> 
            <Route path="/forgot-password" element={<ForgotPasswordPage />} /> 
            <Route path="/reset-password/:token" element={<ResetPassword />} /> 
            <Route path="/bookAppointment" element={<BookAppointmentOutside isMobile={isMobile}/>} /> 
            <Route path="/termsAndConditions" element={<TermsAndConditions />} /> 
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} /> 
            <Route path="/aboutUs" element={<AboutUs />} /> 
            <Route path="/login" element={<ProtectedRoute isAuthenticated={user?false:true} ><LoginPage /></ProtectedRoute>} />
            <Route path="/signup" element={<PersonalInfo/>}/>
          <Route path="/*" element={<ProtectedRoute isAuthenticated={user?true:false} redirect='/login'><AuthenticatedRoutes handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} isMobile={isMobile}/></ProtectedRoute>} />
        </Routes>
      <Toaster/>
     
    </Router>
  );
}

function AuthenticatedRoutes({handleDrawerToggle,mobileOpen,isMobile}) {
  return (
    <Grid container >
        <Grid item xs={12} md={12} height={'51px'}>
        <Suspense fallback={<Loader/>}>         
          <Header isMobile={isMobile} handleDrawerToggle={handleDrawerToggle}/>
        </Suspense>
          <Divider />
        </Grid>
       
        {isMobile ? (
          <Grid item xs={1.99}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            style: {
              position: 'absolute',
              top: '51px',
              height: 'calc(100% - 50px)',
              boxShadow: 'none',
              overflow: 'auto',
            }
          }}
          BackdropProps={{
            sx: {
              top: '50px' // Adjust top position of the Backdrop
            }
          }}
        ><Suspense fallback={<Loader/>}>
          <SideNav  isMobile={isMobile}  handleDrawerToggle={handleDrawerToggle} />
          </Suspense>
        </Drawer>
        </Grid>
      ) : (
        <>
        <Grid item xs={1.99}>
        <Suspense fallback={<Loader/>}>
          <SideNav isMobile={isMobile}  handleDrawerToggle={handleDrawerToggle} />
        </Suspense>
        </Grid>
        <Divider orientation="vertical" flexItem />
        </>
      )}
        <Grid item xs={isMobile ? 12 : 10} style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
        <Suspense fallback={<Loader/>}>
            <Routes >
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/policy/addpolicy" element={<PolicyDetail />} />
              <Route path="/policy/updateDetails" element={<UpdateDetails />} />
              <Route path="/familySpace" element={<FamilySpace />} />
              <Route path="/familySpace/:memberid/:membername" element={<MemberPolicy />} />
              <Route path="/agents" element={<Agents />} />
              <Route path="/agents/:agentId/:agentName" element={<AgentPolicy />} />
              {/* <Route path="/policyCalender" element={<PolicyCalendar />} />
              <Route path="/claimAssistance" element={<ClaimAssistance />} />
              <Route path="/claimAssistance/:claimId/:claimerName" element={<ClaimDetail />} />`` */}
              <Route path="/appointment" element={<Appointment />} />
              <Route path="/appointment/bookAppointment" element={<BookAppointment isMobile={isMobile}/>} />
              {/* <Route path="/downloadSOP" element={<DownloadSOP />} />
              <Route path="/downloadSOP/:policyname" element={<SOPpage />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:blogId" element={<BlogDetail />} /> */}
              {/* <Route path="/test" element={<Test />} /> */}
            </Routes>
          </Suspense>
        </Grid>
    </Grid>
  );
}

export default App;
