import React, { useState } from 'react';
import { Typography, Button, Box, Grid, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { getUser, useLoginMutation } from '../../redux/api/userAPI';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userExist, userNotExist } from '../../redux/reducer/userReducer';
import ForgotPassword from './ForgotPassword';
const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login] = useLoginMutation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const res = await login({ email, password });
      
      if ("data" in res) {
        const token = res.data.token;
        localStorage.setItem("token",token);
        if (!!token) {
          try {
            const data = await getUser(token);
            
            if (data?.user) {
              dispatch(userExist(data.user[0]));
              toast.success(res.data.message);
              // navigate('/dashboard');
            } else {
              dispatch(userNotExist(null));
            }
          } catch (error) {
            dispatch(userNotExist(null));
          }
        } else {
          dispatch(userNotExist(null));
        }
      } else {
        const message = res.error?.data?.message || "An error occurred during login";
        toast.error(message);
      }
    } catch (error) {
      toast.error("Login failed. Please try again.");
    }
  };
  
  return (
    <Grid container  alignItems="center" justifyContent="center" >  
    <Grid item xs={11} md={6} marginTop={['40%','10%']}  boxShadow={3} borderRadius={2} p={4}>
    <Typography textAlign={'center'} variant="h5" gutterBottom>Log In</Typography>
      <Box  
        component="form"
        onSubmit={handleLogin}
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 1,
      }}>
        
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          size="small"
          required
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          size="small"
          required
        />
        <ForgotPassword open={open} handleClose={handleClose} />
        <Button variant="contained" color="primary" sx={{marginTop:'8px'}} fullWidth type="submit">
          Sign in
        </Button>
      </Box>
        <Typography sx={{ textAlign: 'center' }} m={2}>
          <Link
                component="button"
                type="button"
                onClick={handleClickOpen}
                variant="body2"
                sx={{ alignSelf: 'center' }}
              >
                Forgot your password?
          </Link>
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
              Don&apos;t have an account?{' '}
              <Link
                to={"/signup"}
                variant="body2"
                sx={{ alignSelf: 'center' }}
              >
                Sign up
              </Link>
        </Typography>
      
    </Grid>
    
  </Grid>
  );
}

export default LoginPage;