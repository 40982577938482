import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import logo from '../../assests/logo.png'
const AppAppBar = () => {
  return (
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <img src={logo} alt="bhuleganahi" style={{paddingTop:'6px'}} width={'150px'} height={'50px'} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textDecoration: 'none'}}>
          </Typography>
          
          <Button color="inherit" component={Link} to="/login">Login</Button>
          <Button color="inherit" component={Link} to="/signup">Signup</Button>
          <Button color="inherit" component={Link} to="/bookAppointment">Appointment</Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default AppAppBar