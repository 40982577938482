import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
export const authAPI = createApi({
    reducerPath:"authApi",
    baseQuery:fetchBaseQuery({baseUrl:`${process.env.REACT_APP_SERVER}/auth`}),
    endpoints:(builder)=>({
        
        forgotPassword:builder.mutation({
            query:(data)=>({
                url:"forgot-password",
                method:"POST",
                body:data
            }),
        }),
        resetPassword:builder.mutation({
            query:({token,data})=>({
                url:`reset-password/${token}`,
                method:"POST",
                body:data
            }),
        }),
        changePassword:builder.mutation({
            query:(data)=>({
                url:"change-password",
                method:"POST",
                body:data
            }),
        }),
    })
})
export const {useForgotPasswordMutation,useResetPasswordMutation,useChangePasswordMutation} = authAPI;